import { GetStaticProps, NextPage } from "next";
import Link from "next/link";
import Head from "next/head";
import Image from "next/image";
import React from "react";
import { Carousel } from "react-bootstrap";

import { COMPANY_PHONE_NUMBER, META_URL, REVALIDATE_SECONDS } from "@app/utilities/constants";
import * as CmsHttp from "@app/http/cms";
import { QnA } from "@models/Qna";
import { Headline } from "@models/Headline";
import { Service } from "@models/Service";

import teamImg from "@assets/images/homepage/team-800.jpg";

import heroImg from "@assets/images/homepage/hero-1440.jpg";
import counselingImg from "@assets/images/homepage/counseling.jpg";
import symptomsImg from "@assets/images/homepage/symptoms.jpg";
import psychiatryImg from "@assets/images/homepage/psychiatry.jpg";
import assessmentImg from "@assets/images/homepage/assessment.jpg";
import publicImg from "@assets/images/homepage/public.jpg";

import groupsImg from "@assets/images/homepage/groups.jpg";
import marriageImg from "@assets/images/homepage/marriage.jpg";
import neurofeedbackImg from "@assets/images/homepage/neurofeedback.png";
import tmcImg from "@assets/images/homepage/tmc.jpg";

type LinkMapType = {
  text: string;
  href: string;
};

interface IHomePage {
  featuredFaqs: QnA[];
  featuredHeadlines: Headline[];
  frequentlyIssuesTreated: Service[];
}

const HomePage: NextPage<IHomePage> = ({ featuredFaqs, featuredHeadlines, frequentlyIssuesTreated }) => {
  const frequentIssuesTreated: LinkMapType[] = frequentlyIssuesTreated.map((service) => {
      return {
        text: service.name,
        href: `/services/${service.slug}`,
      };
    }),
    headlines: Headline[] = featuredHeadlines,
    qnas: QnA[] = featuredFaqs;

  /**
   * Meta contents
   */
  const metaUrl = META_URL;

  return (
    <div className="landing-page" data-testid="landing-page">
      <Head>
        <link rel="canonical" href={metaUrl} />
        <link rel="preload" as="image" href={heroImg.src} />
      </Head>
      <section className="hero">
        <div className="container">
          <div className="title-wrapper">
            <h1 className="title"> Professional Counseling Nets Positive Results </h1>
            <p className="tagline">Heritage Counseling &amp; Consulting, P.A. is a premier counseling center focused on the total well-being of our clients and patients.</p>
            <a title="Phone" aria-label="Phone" href={`tel:${COMPANY_PHONE_NUMBER}`} className="btn btn-external btn-main px-5 py-3" rel="noopener noreferrer">
              <i className="fa fa-phone-alt mr-2"></i>
              <span>Contact Us</span>
            </a>
          </div>
        </div>
      </section>
      <Carousel controls={false} interval={1e4} keyboard={false} slide={true} className="headline h-div">
        {headlines.map((headline, idx) => {
          return (
            <Carousel.Item key={idx}>
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: headline?.text?.html,
                }}
              ></div>
            </Carousel.Item>
          );
        })}
      </Carousel>

      <section className="intro gap">
        <div className="container wrapper">
          <div className="d-none d-lg-block">
            <img className="img-responsive" loading="lazy" src={teamImg.src} alt="Heritage Team" width="611" height="481" />
          </div>
          <div className="d-block">
            <h2 className="title">Welcome to Heritage Counseling &amp; Consulting</h2>
            <p>Heritage Counseling &amp; Consulting, P.A. is a premier counseling center focused on the total well-being of our clients and patients.</p>
            <p>
              Our approach is to create an attentive, supportive, and professional relationship. We encourage a collaborative approach to treatment, with an emphasis on consulting with current or referred medical providers when necessary to
              make decisions about treatment plans, including those involving medications.
            </p>
            <p className="mb-5">Our mission is to provide the most innovative and up to date treatments and services in our field. We approach our work with passion and integrity.</p>
            <div className="text-center text-lg-left">
              <Link href="/about" shallow={true}>
                <a className="btn btn-main btn-cta" aria-label="About">
                  Read more about us
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="about gap">
        <div className="title-container">
          <h3 className="title">We specialize in meeting the specific and unique needs of each person who enters our practice.</h3>
        </div>
        <div className="container">
          <div className="grid-container">
            <Link href="/services?ctg=counseling" shallow={true}>
              <a className="figure-link" aria-label="Counseling">
                <figure>
                  <Image className="" src={counselingImg.src} alt="Counseling Service" layout="fill" objectFit="cover" loading="lazy" />
                  <figcaption>Counseling Service</figcaption>
                </figure>
              </a>
            </Link>
            <Link href="/services?ctg=symptoms" shallow={true}>
              <a className="figure-link" aria-label="Symptoms">
                <figure>
                  <Image className="" src={symptomsImg.src} alt="Symptoms Treated" layout="fill" objectFit="cover" loading="lazy" />
                  <figcaption>Symptoms Treated</figcaption>
                </figure>
              </a>
            </Link>
            <Link href="/services?ctg=psychiatry" shallow={true}>
              <a className="figure-link" aria-label="Psychiatry">
                <figure>
                  <Image className="" src={psychiatryImg.src} alt="Psychiatry" layout="fill" objectFit="cover" loading="lazy" />
                  <figcaption>Psychiatry</figcaption>
                </figure>
              </a>
            </Link>
            <Link href="/services?ctg=alternative+treatment+options" shallow={true}>
              <a className="figure-link" aria-label="Neurofeedback">
                <figure>
                  <Image className="" src={neurofeedbackImg.src} alt="Neurofeedback" layout="fill" objectFit="cover" loading="lazy" />
                  <figcaption>Neurofeedback</figcaption>
                </figure>
              </a>
            </Link>
            <Link href="/services?ctg=testing+%26+assessment" shallow={true}>
              <a className="figure-link" aria-label="Assessment">
                <figure>
                  <Image className="" src={assessmentImg.src} alt="Assessment" layout="fill" objectFit="cover" loading="lazy" />
                  <figcaption>
                    <span className="d-none d-xl-inline">Testing &amp;&nbsp;</span>Assessment
                  </figcaption>
                </figure>
              </a>
            </Link>
            <Link href="/services?ctg=speaking+engagements" shallow={true}>
              <a className="figure-link" aria-label="Public Speaking">
                <figure>
                  <Image className="" src={publicImg.src} alt="Public Speaking" layout="fill" objectFit="cover" loading="lazy" />
                  <figcaption>Public Speaking</figcaption>
                </figure>
              </a>
            </Link>
            <Link href="/services?ctg=group+therapy" shallow={true}>
              <a className="figure-link" aria-label="Group Therapy">
                <figure>
                  <Image className="" src={groupsImg.src} alt="Group Therapy" layout="fill" objectFit="cover" loading="lazy" />
                  <figcaption>Group Therapy</figcaption>
                </figure>
              </a>
            </Link>
            <Link href="/services?ctg=alternative+treatment+options" shallow={true}>
              <a className="figure-link" aria-label="TMS">
                <figure>
                  <Image className="" src={tmcImg.src} alt="TMS" layout="fill" objectFit="cover" loading="lazy" />
                  <figcaption>TMS</figcaption>
                </figure>
              </a>
            </Link>
            <Link href="/services?ctg=marriage+retreats" shallow={true}>
              <a className="figure-link" aria-label="Marriage Retreats">
                <figure>
                  <Image className="" src={marriageImg.src} alt="Marriage Retreats" layout="fill" objectFit="cover" loading="lazy" />
                  <figcaption>Marriage Retreats</figcaption>
                </figure>
              </a>
            </Link>
          </div>
        </div>
      </section>

      <div className="athen-section">
        <section className="about fly">
          <div className="title-container">
            <h3 className="title">Our most frequently treated issues include:</h3>
          </div>
          <div className="container">
            <div className="grid-container">
              {frequentIssuesTreated.map((specialty, idx) => {
                return (
                  <Link key={idx} href={specialty.href}>
                    <a className="text-link" aria-label={specialty.text}>
                      {specialty.text}
                    </a>
                  </Link>
                );
              })}
            </div>
          </div>
        </section>

        <section className="qna-section" itemScope itemType="https://schema.org/FAQPage">
          {qnas.map((qna, idx) => {
            return (
              <section className="qna gap" key={idx} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
                <div className="title-container">
                  <h3 className="title" itemProp="name">
                    {qna.question}
                  </h3>
                </div>
                <div className="container wrapper" itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <div className="answer" itemProp="text" dangerouslySetInnerHTML={{ __html: qna.answer.html }}></div>
                </div>
              </section>
            );
          })}
          <div className="container text-center mb-10">
            <Link href="/faqs">
              <a className="btn btn-main btn-cta" aria-label="FAQ">
                Read More FAQs
              </a>
            </Link>
          </div>
        </section>
      </div>
    </div>
  );
};
export const getStaticProps: GetStaticProps = async () => {
  const [featuredFaqs, featuredHeadlines, frequentlyIssuesTreated] = await Promise.all([CmsHttp.getFeaturedFaqs(), CmsHttp.getHeadlines(), CmsHttp.getFrequentlyIssuesTreated()]);

  return {
    props: {
      featuredFaqs,
      featuredHeadlines,
      frequentlyIssuesTreated,
    } as IHomePage,
    revalidate: REVALIDATE_SECONDS, // In seconds
  };
};

export default HomePage;
